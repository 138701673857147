<template>
  <v-container fluid>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <div v-if="cargando" class="fill-height">
      <v-card>
        <v-card-text>
          <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="list-item, image, list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <h3 class="pa-2 mt-n2">{{ item.nombre }}</h3>
      <v-row>
        <v-col cols="12" md="6"
          ><v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8"> <v-img :src="item.foto"></v-img></v-col>
                <v-col cols="4">
                  <p class="headline">
                    <v-icon :color="item.stock > 0 ? 'green' : 'red'"
                      >mdi-{{ item.stock > 0 ? "check" : "close" }}</v-icon
                    >
                    {{ item.stock > 0 ? "En" : "Sin" }} stock
                  </p>
                  <h1 class="pa-2">$ {{ item.precio }}</h1>
                  <v-btn icon block class="mt-4"
                    ><v-icon color="red">mdi-heart-outline</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-alert dense type="warning" icon="mdi-sale" v-for="(item, index) in masPrecios" :key="index">
                <b>$ {{item.precio}}</b> a partir de {{item.cantidad}} u
                </v-alert>
              <v-btn
                block
                color="green"
                class="mt-2"
                dark
                large
                v-if="item.stock > 0"
                @click="agregarCarrito()"
              >
                Agregar al carrito</v-btn
              >
              <v-alert
                class="mt-2"
                type="info"
                icon="mdi-basket"
                border="top"
                v-if="estaEnCarrito"
                >Tenes {{ estaEnCarrito.cantidad }} u. de este producto en tu
                carrito por un total de $ {{ estaEnCarrito.total }}.
                <v-btn text block @click="$router.push('/carrito')"
                  ><v-icon>mdi-arrow-right</v-icon> Ver pedido</v-btn
                ></v-alert
              >
            </v-card-text>
          </v-card></v-col
        >
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-card
                v-if="item.descripcion || item.caracteristicas.length > 0"
              >
                <v-card-text>
                  <p v-if="item.descripcion">{{ item.descripcion }}</p>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-left"
                            v-for="(c, i) in item.caracteristicas"
                            :key="i"
                          >
                            {{ c.caracteristica.nombre }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td v-for="(c, i) in item.caracteristicas" :key="i">
                            {{ c.valor }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-text
                  ><ListadoProductos
                    titulo="Te puede interesar"
                    :items="items"
                    :cargando="cargandoItems"
                  ></ListadoProductos>
                  <v-btn block text @click="verMasProductos()"
                    ><v-icon>mdi-arrow-right</v-icon> Ver mas productos</v-btn
                  ></v-card-text
                ></v-card
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import ListadoProductos from "../components/ListadoProductos";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Productos",
  metaInfo() {
    return {
      title: `${this.item.nombre} - Insumos para Sublimación`,
      meta: [
        {
          name: "description",
          content:
            "➡️ Ver este producto y más en nuestro sitio web. Precios y stock real ✅",
        },
        { property: "og:title", content: `${this.item.nombre}` },
        { property: "og:site_name", content: "Cayetano Grafica" },
        {
          property: "og:description",
          content:
            "➡️ Ver este producto y más en nuestro sitio web. Precios y stock real ✅",
        },
        { property: "og:type", content: "profile" },
        {
          property: "og:url",
          content: "https://cayetanografica.com.ar/producto/" + this.slug,
        },
        { property: "og:image", content: this.item.foto },
      ],
    };
  },
  components: { ListadoProductos },
  data: () => ({
    cargando: true,
    item: {
      _id: "",
      nombre: "",
      categoria: {
        nombre: "",
      },
      caracteristicas: [],
    },
    titulo: undefined,
    items: [],
    cargandoItems: true,
  }),
  computed: {
    ...mapState(["carrito"]),
    slug() {
      return this.$route.params.slug;
    },
    breadcrumbs() {
      return [
        {
          text: "Ir a la Tienda",
          disabled: false,
          to:
            this.$route.params.anterior &&
            this.$route.params.anterior.name == "Tienda"
              ? this.$route.params.anterior.fullPath
              : "/tienda",
        },
      ];
    },
    estaEnCarrito() {
      let obj = this.carrito.productos.find((val) => val._id == this.item._id);
      return obj ? obj : 0;
    },
    masPrecios() {
      return this.item.precios.filter(v => v.cantidad != 0)
    }
  },
  methods: {
    ...mapMutations(["dialogAgregarCarrito"]),
    async obtenerProducto() {
      this.cargando = true;
      try {
        let producto = await this.axios.get(`/producto/${this.slug}`);
        this.item = producto.data;
        this.titulo = producto.data.nombre;
        this.obtenerSugeridos();
      } catch (error) {
        this.$router.push("/tienda");
      }
      this.cargando = false;
    },
    async obtenerSugeridos() {
      this.cargandoItems = true;
      try {
        const items = await this.axios.post("/productos", {
          parametros: {
            texto: this.item.categoria.nombre,
            excluirId: this.item._id,
          },
        });
        this.items = items.data.docs;
      } catch (error) {
        console.log(error);
      }
      this.cargandoItems = false;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    verMasProductos() {
      this.$router.push(
        this.$route.params.anterior &&
          this.$route.params.anterior.name == "Tienda"
          ? this.$route.params.anterior.fullPath
          : "/tienda"
      );
    },
    agregarCarrito() {
      if (this.estaEnCarrito) {
        this.item = this.estaEnCarrito;
        this.item.editar = true;
      } else {
        this.item.editar = false;
      }
      this.dialogAgregarCarrito({ item: this.item, estado: true });
    },
  },
  created() {
    this.obtenerProducto();
  },
  watch: {
    slug() {
      this.obtenerProducto();
      this.scrollToTop();
    },
  },
};
</script>
